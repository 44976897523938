@charset "utf-8";

// ---- Bulma Customization ----

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

// Implicit Defines

$background : #FFFEF2;
$background-accent: #E0E0DA;

// Variable Overrides

$body-background-color :  $background;

@import "~bulma/bulma";

// Import only what you need from Bulma
//@import "../node_modules/bulma/sass/elements/button.sass"
//@import "../node_modules/bulma/sass/elements/container.sass"
//@import "../node_modules/bulma/sass/elements/title.sass"
//@import "../node_modules/bulma/sass/form/_all.sass"
//@import "../node_modules/bulma/sass/components/navbar.sass"
//@import "../node_modules/bulma/sass/layout/hero.sass"
//@import "../node_modules/bulma/sass/layout/section.sass"


// Style Overrides

.navbar-divider {
  background-color : $background-accent; }

footer.footer {
  background-color : $background-accent; }


// ---- Main Page ----

.interactive-background {
  opacity: 0;
  transition: opacity 0.5s ease-out; }

.interactive-background:hover .interactive-background:active {
  opacity: 100%;
  transition: opacity 0.25s ease-in; }

.gallery {
  margin-left: 8px;
  margin-right: 8px;
  transition: margin 0.25s ease-out; }

.gallery:hover .gallery:active {
  margin-left: 0;
  margin-right: 0;
  transition: margin 0.15s ease-in; }

